@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

  /* this three lines prevent select text in website */

  /* -webkit-user-select: none;
  -ms-user-select: none; 
  user-select: none;  */

  /* font-family: inter, sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #999;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

html {
  scroll-behavior: smooth;
}

.input-focus:focus {
  outline: none;
}

::selection {
  background: #90ee90;
}

:root {
  --primary-main: #0d8644;
  --secondary-main: #db3a3e;
  --primaryText-main: #4a4a4a;
  --secondaryText: #8e8e8e;
  --cardBackgroung-main: #c8f5f4;
  --outline-main: #eaeaec;
  --white-main: #ffffff;
  --black-main: #000000;
}

.underline-button {
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.underline-button:hover {
  color: #db3a3e;
}

.underline-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #db3a3e;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.underline-button:hover::before {
  transform: scaleX(1);
}

.textHover:hover {
  color: #f7f965;
  transition: 0.3s;
  cursor: pointer;
}

.navtextHover:hover {
  /* color: #e6ffff; */
  /* color: #97ebdb; */
  color: #00b5b7;
  transition: 0.3s;
  cursor: pointer;
  background-color: white;
}

.navtext:hover {
  transition: 0.3s;
  cursor: pointer;
  color: #00b5b7;
}

.arrow-top:after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid black;
  z-index: 5000;
}

.pngShadow {
  filter: drop-shadow(0px 2px 8px #989898);
}

/* Navbar under line Animation */
.navUnderline {
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.navUnderline:hover {
  color: #0d8644;
}

.navUnderline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #0d8644;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.navUnderline:hover::before {
  transform: scaleX(1);
}

.css-1v9t3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  color: #db3a3e !important;
  /* background-color: white; */
  /* border-radius: 50%; */
  min-width: 15% !important;
}

.MuiBottomNavigationAction-root {
  color: white !important;
  /* border: 1px solid blue !important; */
  min-width: 15% !important;
  min-height: 60px !important;
  padding: 2rem;
  margin: 0px;
  padding: 0px;
}

.box:nth-child(even) {
  display: flex;
  flex-direction: row-reverse;
}

.fileUploader > .sc-aXZVg,
.guZdik {
  min-width: 100% !important;  
}

.uploadAgreement > .sc-aXZVg,
.guZdik {
  min-width: 100% !important;
  /* margin-bottom: 1rem; */
}

/* .uploadPassbookImage > .sc-aXZVg,
.guZdik {
  min-width: 100% !important;
  margin-bottom: 1rem;
} */


